import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as searchableContent from '../../../../data/models/searchable-content';
import { SearchService } from '@app/services/search.service';
import { SearchableContent } from '@models';
import { SearchResultViewModel } from 'app/data/models/search-result-view-model';
import { Observable, throwError, Subject, of, EMPTY } from 'rxjs';
import {
  mergeMap,
  filter,
  catchError,
  takeUntil,
  tap,
  take
} from 'rxjs/operators';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  @Input() contentType: SearchableContent;
  public title = '';
  public resultCount = 0;
  public results: SearchResultViewModel[];
  public displayedResults: SearchResultViewModel[];
  public spinning: boolean;
  public SearchableContent = searchableContent.SearchableContent;
  public initiated: boolean;
  public collapsedDisplayedResultCount: number;
  public isExpandable: boolean;
  public isCollapsible: boolean;
  public errored: boolean;
  private query = '';
  public searchResultId: string;

  constructor(private searchService: SearchService) {}

  ngOnInit() {
    this.searchResultId = `search-results-${this.contentType}`;
    switch (this.contentType) {
      case SearchableContent.documents:
        this.title = 'Reference';
        this.collapsedDisplayedResultCount = 5;
        break;
      case SearchableContent.links:
          this.title = 'Links';
          this.collapsedDisplayedResultCount = 6;
          break;
      case SearchableContent.dailyTasks:
        this.title = 'Daily Tasks';
        this.collapsedDisplayedResultCount = 4;
        break;
      case SearchableContent.calendar:
        this.title = 'Calendar';
        this.collapsedDisplayedResultCount = 4;
        break;
      case SearchableContent.needToKnow:
        this.title = 'Need to Know';
        this.collapsedDisplayedResultCount = 4;
        break;
      default:
        break;
    }

    this.subscribeToSearchQuery();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public subscribeToSearchQuery() {
    this.searchService
      .searchQuery()
      .pipe(
        filter(query => !!query),
        mergeMap(query => {
          this.query = query;
          return this.search();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  public retrySearch() {
    this.search()
      .pipe(take(1))
      .subscribe();
  }

  private search(): Observable<SearchResultViewModel[]> {
    this.displayedResults = [];
    this.isExpandable = false;
    this.isCollapsible = false;
    this.initiated = true;
    this.errored = false;
    this.spinning = true;

    return this.searchService.performSearch(this.query, this.contentType).pipe(
      // handle error state from API (i.e. lost network) and return empty observable to keep subscription alive
      catchError(err => {
        this.onError();
        return EMPTY;
      }),
      tap(results => this.applyResults(results))
    );
  }

  private onError() {
    this.errored = true;
    this.spinning = false;
  }

  private applyResults(results: SearchResultViewModel[]) {
    this.results = results;
    this.resultCount = this.results.length;

    this.displayedResults =
      this.resultCount > this.collapsedDisplayedResultCount
        ? results.slice(0, this.collapsedDisplayedResultCount)
        : results;

    this.spinning = false;

    this.toggleExpandCollapseState(false);
  }

  public toggleShowAllCollapse() {
    this.displayedResults =
      this.displayedResults.length < this.resultCount
        ? this.results
        : this.results.slice(0, this.collapsedDisplayedResultCount);

    this.toggleExpandCollapseState();
  }

  private toggleExpandCollapseState(scroll: boolean = true) {
    this.isExpandable = this.displayedResults.length < this.resultCount;
    this.isCollapsible =
      this.displayedResults.length > this.collapsedDisplayedResultCount;

    if (scroll) {
      setTimeout(this.scroll, 100, this.searchResultId);
    }
  }

  private scroll(id: string) {
    const itemToScrollTo = document.getElementById(id);

    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  }
}
