import { Directive, HostListener } from '@angular/core';
import { AppLinkService } from '@app/services';
import { Target } from '@models';

@Directive({
  selector: '[appContentLinks]'
})
export class ContentLinksDirective {
  constructor(private linkService: AppLinkService) {}

  @HostListener('click', ['$event'])
  public documentClick(e: any): void {
    this.handleClick(e);
  }

  private handleClick($event: any) {
    if ($event.target.tagName.toLowerCase() === 'a' && $event.target.href) {
      const link: string = $event.target.href;
      if (this.linkService.isLinkFileOrAsset(link)) {
        $event.stopPropagation();
        $event.preventDefault();
        this.linkService.handleFileOrAssetLink(link);
      } else {
        $event.target.target = Target.blank;
      }
    }
  }
}
