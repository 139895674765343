<div *ngIf="initiated" id="{{ searchResultId }}">
  <div class="search-results__header">
    <div>
      {{ title }}
    </div>
    <div
      *ngIf="isExpandable"
      (click)="toggleShowAllCollapse()"
      class="search-results--pointer"
    >
      Show All ({{ resultCount }})
    </div>
    <div
      *ngIf="isCollapsible"
      (click)="toggleShowAllCollapse()"
      class="search-results--pointer"
    >
      Collapse
    </div>
  </div>
  <div [ngSwitch]="contentType" class="search-results__contents">
    <div *ngIf="spinning" class="search-results__contents__shade">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div
      *ngIf="!resultCount && !spinning && !errored"
      class="search-results__contents__no-results"
    >
      No Results
    </div>
    <div
      *ngIf="errored"
      class="search-results__contents__shade--pointer"
      (click)="retrySearch()"
    >
      <span>Unable to load results.</span>
      <span>Tap to Retry.</span>
    </div>
    <ng-container *ngSwitchCase="SearchableContent.dailyTasks">
      <app-search-daily-task-result
        *ngFor="let result of displayedResults"
        class="search-results__contents__daily-task"
        [result]="result"
      >
      </app-search-daily-task-result>
    </ng-container>
    <ng-container *ngSwitchCase="SearchableContent.needToKnow">
      <app-search-need-to-know-result
        *ngFor="let result of displayedResults"
        class="search-results__contents__need-to-know"
        [result]="result"
      >
      </app-search-need-to-know-result>
    </ng-container>
    <ng-container *ngSwitchCase="SearchableContent.calendar">
      <app-search-calendar-event-result
        *ngFor="let result of displayedResults"
        class="search-results__contents__calendar-event"
        [result]="result"
      >
      </app-search-calendar-event-result>
    </ng-container>
    <ng-container *ngSwitchCase="SearchableContent.documents">
      <app-search-reference-result
        *ngFor="let result of displayedResults"
        class="search-results__contents__reference"
        [result]="result"
      >
      </app-search-reference-result>
    </ng-container>
    <ng-container *ngSwitchCase="SearchableContent.links">
      <app-search-link-result
        *ngFor="let result of displayedResults"
        class="search-results__contents__link"
        [result]="result"
      >
      </app-search-link-result>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div
        *ngFor="let result of displayedResults"
        class="search-results__contents__default"
      >
        {{ result.title }}
      </div>
    </ng-container>
  </div>
</div>
