import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { StoreListModal, UserViewModel } from '@models';
import { ModalService, AuthService } from '@app/services';

import { StoreGroupViewModel } from '@models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-store-list-view',
  templateUrl: './store-list-view.component.html',
  styleUrls: ['./store-list-view.component.scss']
})
export class StoreListViewComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  @Input()
  public textBlack: boolean;
  @Input()
  public storeGroups: StoreGroupViewModel[] = [];

  public storeListModalConfig: StoreListModal;
  public shouldShowStoreListButton: boolean;
  public isFieldLeader: boolean;

  constructor(
    private modalService: ModalService,
    private authService: AuthService
  ) {
    this.storeListModalConfig = {
      id: 'store-list-modal',
      storeGroups: this.storeGroups
    };
  }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.isFieldLeader = UserViewModel.isFieldLeader(user);
        this.setWhetherToShowViewStore();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const storeGroupsChange = changes.storeGroups;
    if (storeGroupsChange) {
      this.setWhetherToShowViewStore();
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public openStoreListModel(): void {
    this.storeListModalConfig.storeGroups = this.storeGroups;
    this.modalService.open(this.storeListModalConfig.id);
  }

  public setWhetherToShowViewStore() {
    if (this.storeGroups && this.storeGroups.length === 0) {
      this.shouldShowStoreListButton = false;
      return;
    }

    this.shouldShowStoreListButton = this.isFieldLeader;
  }
}
