<div
  class="search__calendar-event__result"
  [style.border-left-color]="result.borderColor"
  appLink
  [link]="result.link"
>
  <div class="search__calendar-event__result__title">
    {{ result.title }}
  </div>
</div>
