import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private previouseTitle: string;
  private ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public setBreadcrumbTitleFromRoute(): Observable<Event> {
    const observableEvent = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => {
        const route = this.activatedRoute;
        this.updateTitle(this.getBreadcrumbTitle(route));
      })
    );

    return observableEvent;
  }

  private getBreadcrumbTitle(route: ActivatedRoute): string {
    const label = route.routeConfig
      ? route.routeConfig.data[this.ROUTE_DATA_BREADCRUMB]
      : '';
    if (route.firstChild) {
      return this.getBreadcrumbTitle(route.firstChild);
    }
    return label;
  }
  public updateTitle(title: string) {
    if (this.titleSubject.value !== 'Alerts' && this.titleSubject.value !== 'Search') {
      this.previouseTitle = this.titleSubject.value;
    }
    this.titleSubject.next(title);
  }

  public getTitle(): Observable<string> {
    return this.titleSubject.asObservable();
  }

  public revertTitle() {
    this.titleSubject.next(this.previouseTitle);
  }
}
