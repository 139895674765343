<div
  class="search__daily-tasks__result"
  [style.border-left-color]="result.borderColor"
  appLink
  [link]="result.link"
>
  <div
    class="search__daily-tasks__result__department"
    [style.color]="result.borderColor"
  >
    {{ result.department }}
  </div>
  <div class="search__daily-tasks__result__title">
    {{ result.title }}
  </div>
</div>
