<div
  class="document-viewer"
  *ngIf="config"
  [ngClass]="{
    'document-viewer--noscroll': config.documentType !== DocumentType.PDF
  }"
>
  <div class="document-viewer__header">
    <div class="document-viewer__header--actions">
      <a (click)="printFile()">
        <app-svg-icon iconName="printer"></app-svg-icon>
      </a>
      <a
        (click)="openInBrowser()"
        title="open in browser"
        *ngIf="config.documentType === DocumentType.PDF"
      >
        <app-svg-icon iconName="open-in-browser"></app-svg-icon>
      </a>
      <a (click)="refreshContent()">
        <app-svg-icon iconName="refresh"></app-svg-icon>
      </a>
    </div>
    <div class="document-viewer__header--title">
      {{ config.title }}
    </div>
    <div class="document-viewer__header--close">
      <app-svg-icon
        class=""
        iconName="close-light"
        (click)="close()"
      ></app-svg-icon>
    </div>
  </div>
  <div [ngSwitch]="config.documentType" class="document-viewer__body">
    <div
      class="document-viewer__body__content--loading"
      *ngSwitchCase="DocumentType.PDF"
      [ngClass]="{ 'document-viewer__body__content--loaded': contentLoaded }"
    >
      <pdf-viewer
        [src]="content"
        [render-text]="true"
        (error)="onError($event)"
        (after-load-complete)="afterPDFContentLoaded($event)"
        (text-layer-rendered)="afterTextRendered()"
        id="pdf"
      ></pdf-viewer>
    </div>

    <div
      class="document-viewer__body__content--loading document-viewer__body__content__video"
      *ngSwitchCase="DocumentType.Video"
      [ngClass]="{ 'document-viewer__body__content--loaded': contentLoaded }"
    >
      <video
        *ngIf="content && content.url"
        controls
        autoplay
        controlslist="nodownload"
        disablePictureInPicture
        (loadeddata)="afterContentLoaded()"
        (playing)="onPlayingVideo($event)"
        class="document-viewer__body__content__object__video"
      >
        <source [src]="content.url" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div
      class="document-viewer__body__content document-viewer__body__content--image"
      *ngSwitchCase="DocumentType.Image"
    >
      <img
        *ngIf="content && content.url"
        class="document-viewer__body__content__object__image"
        [src]="content.url"
      />
    </div>
  </div>
</div>

<app-modal [config]="modalConfig">
  <app-modal-action
    [title]="'Cancel'"
    (clickEvent)="closeModal('ms-file-modal')"
  ></app-modal-action>
</app-modal>
