import { FileType } from './../../../data/models/file';
import {
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnInit,
  ViewChild
} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { SafeStyle } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  DocumentViewerService,
  LoaderService,
  FileService,
  ModalService
} from '@app/services';
import { DocumentViewerModal, DocumentFileType, Target, Modal } from '@models';
import * as models from '@models';
import { PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FileTypeUtility } from '@app/utilities';
import { environment } from '@env';
import { LocalStorage } from '@app/utilities/local-storage';
import { APP_CONFIGURATION } from '@app/config/app-configuration';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  private pdf: PDFDocumentProxy;
  private unsubscribe$ = new Subject<boolean>();

  @Output() loaded: EventEmitter<any> = new EventEmitter();

  private video: HTMLVideoElement;

  public config: DocumentViewerModal;

  public contentLoaded = false;
  public content: any;
  public safeStyle: SafeStyle;

  private element: HTMLElement;

  public DocumentType = models.DocumentFileType;

  private searchQuery: string;
  private searchInitiated: boolean;
  private isSafari: boolean;

  public modalConfig: Modal;
  private systemType: string;

  constructor(
    private documentViewerService: DocumentViewerService,
    private loaderService: LoaderService,
    el: ElementRef,
    private renderer: Renderer2,
    private fileService: FileService,
    private modalService: ModalService
  ) {
    this.element = el.nativeElement;

    this.systemType = LocalStorage.get(
      APP_CONFIGURATION.LOCAL_STORAGE_KEYS.SYSTEM_TYPE
    );
  }

  ngOnInit(): void {
    this.renderer.appendChild(document.body, this.element);
    this.documentViewerService
      .onOpen()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => {
        if (config) {
          this.renderContent(config);
        }
      });

    this.documentViewerService
      .onClose()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.close());

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    this.configureModal();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  // open document viewer
  public open(config: DocumentViewerModal): void {
    this.config = config;

    if (this.config.url) {
      this.setModalContent();
      this.renderer.setStyle(this.element, 'display', 'block');
    } else {
      this.renderer.setStyle(this.element, 'display', 'block');
      this.fileService
        .returnFileUrlAsync(
          this.config.fileType, this.config.fileName,
          this.config.documentType === DocumentFileType.Video
        )
        .then(url => {
          this.config.url = url.toJSON();
          this.setModalContent();
        });
    }
  }

  private setModalContent() {
    if (this.config.documentType === DocumentFileType.PDF) {
      this.setPdfWorkerLocation();
      this.searchQuery = this.config.searchTerm;
      this.searchInitiated = false;
      this.content = {
        url: this.config.url,
        httpHeader: new HttpHeaders({
          'Cache-Control': 'no-cache'
        })
      };
    } else {
      this.content = {
        url: this.config.url
      };
      if (this.config.documentType !== DocumentFileType.Video) {
        this.loaderService.hideLoader();
      }
    }
  }

  // close document viewer
  public close(): void {
    this.config = null;
    this.contentLoaded = false;
    this.searchQuery = null;
    this.searchInitiated = false;
    this.content = null;
    this.video = null;
    this.renderer.setStyle(this.element, 'display', 'none');
  }

  public afterPDFContentLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.afterContentLoaded();
  }

  public afterContentLoaded() {
    this.contentLoaded = true;
    this.loaderService.hideLoader();
  }

  public printFile() {
    if (this.config) {
      switch (this.config.documentType) {
        case DocumentFileType.PDF:
          this.printPDF();
          break;
        case DocumentFileType.Video:
          window.print();
          break;
        case DocumentFileType.Image:
          window.print();
          break;
        default:
          window.print();
          break;
      }
    }
  }

  public openInBrowser() {
    if (this.pdf && this.config) {
      this.pdf.getData().then(data => {
        const blob = new Blob([data.buffer], {
          type: 'application/pdf'
        });

        if (!this.isSafari) {
          const blobUrl = URL.createObjectURL(blob);
          const blobWindowRef = window.open(blobUrl, Target.blank);
          if (blobWindowRef) {
            blobWindowRef.onbeforeunload = () => {
             window.URL.revokeObjectURL(blobUrl);
           };
          } return;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
          window.open(reader.result as string, Target.blank);
        };

        reader.readAsDataURL(blob);
      });
    }
  }

  public refreshContent() {
    this.contentLoaded = false;
    this.content = null;

    if (this.config.documentType === DocumentFileType.Video) {
      this.video.pause();
      this.fileService.returnFileUrlAsync(this.config.fileType, this.config.fileName, true, true)
        .then(url => {
          this.config.url = url.href;
          this.setModalContent();
          this.video.load();
          this.video.play();
        });
    } else if (this.config && this.config.url) {
      const cacheBustUrl = new URL(
        this.fileService.returnAPIUrl(this.config.url)
      );
      cacheBustUrl.searchParams.append(
        'cache',
        new Date().getTime().toString()
      );
      this.config.url = cacheBustUrl.href;
      this.renderContent(this.config);
    }
  }

  public onPlayingVideo(event: Event) {
    this.video = event.target as HTMLVideoElement;
  }

  public search() {
    if (this.searchInitiated || !this.searchQuery) {
      return;
    }

    if (this.pdfComponent) {
      this.pdfComponent.eventBus.dispatch('find', {
        caseSensitive: false,
        findPrevious: undefined,
        highlightAll: true,
        phraseSearch: true,
        query: this.searchQuery
      });

      this.searchInitiated = true;
    }
  }

  public afterTextRendered() {
    this.search();
    this.updateAnchors();
  }

  public updateAnchors() {
    const pdf = document.getElementById('pdf');
    const anchors = pdf.getElementsByTagName('a');

    for (const anchor of anchors as any) {
      const a = anchor as HTMLAnchorElement;
      a.onclick = (e: MouseEvent) => {
        let updatedUrl = a.href;

        if (
          updatedUrl.startsWith(window.location.origin) &&
          updatedUrl.includes('/api/v1/files')
        ) {
          this.close();
          e.preventDefault();

          if (environment.env === 'local') {
            updatedUrl = `${a.href}`;
            const url = new URL(updatedUrl);
            updatedUrl = `${environment.apiUrl}${url.pathname}?noredirect=true`;
          }

          const config = this.configureDocModal(updatedUrl);

          this.renderContent(config);
        } else {
          a.target = Target.blank;
        }
      };
    }
  }

  public onError(err: any) {
    console.log(err);
    this.loaderService.hideLoader();
  }

  private renderContent(config: DocumentViewerModal) {
    if (
      config.documentType === DocumentFileType.Image ||
      config.documentType === DocumentFileType.PDF ||
      config.documentType === DocumentFileType.Video
    ) {
      this.loaderService.showLoader();
      this.open(config);
    } else {
      this.loaderService.showLoader();
      this.downloadFile(config);
    }
  }

  private downloadFile(config: DocumentViewerModal) {
    if (
      config.documentType === DocumentFileType.Microsoft_Doc &&
      this.systemType &&
      this.systemType !== APP_CONFIGURATION.SYSTEM_TYPES.WORKSTATION
    ) {
      this.openModal('ms-file-modal');
      this.loaderService.hideLoader();
      return;
    }

    this.fileService
      .returnFileUrlAsync(config.fileType, config.fileName)
      .then(url => {
        this.loaderService.hideLoader();
        window.location.href = url.toJSON();
      });
  }

  private printPDF() {
    if (this.pdf && this.config) {
      this.pdf.getData().then(data => {
        const blob = new Blob([data.buffer], {
          type: 'application/pdf'
        });
          const blobUrl = URL.createObjectURL(blob);

          // Safari Browser
          if (this.isSafari) {
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.src = blobUrl;

            setTimeout(() => {
              if (iframe) {
                iframe.contentWindow.print();
              }
              window.URL.revokeObjectURL(blobUrl);
            }, 1000);
          } else {
            // Chrome / FF / Edge Chromium
            window.open(blobUrl).print();
            window.URL.revokeObjectURL(blobUrl);
          }
      });
    }
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    this.element.remove();
  }

  private configureDocModal(urlString: string): DocumentViewerModal {
    const decodedURL = decodeURI(urlString.split('?')[0]);

    const parsedTitle = decodedURL.substring(
      decodedURL.lastIndexOf('/') + 1,
      decodedURL.lastIndexOf('.')
    );

    const fileExtension = decodedURL.substring(decodedURL.lastIndexOf('.') + 1);
    const docType = FileTypeUtility.getDocumentFileType(fileExtension);

    const parsedFileType = decodedURL.indexOf(`/${FileType.file}/`)
      ? FileType.file
      : FileType.asset;

    const config: DocumentViewerModal = {
      title: parsedTitle,
      url: urlString,
      documentType: docType,
      fileType: parsedFileType
    };

    if (docType === DocumentFileType.Microsoft_Doc) {
      config.fileName = `${parsedTitle}.${fileExtension}`;
    }

    return config;
  }

  private setPdfWorkerLocation() {
    (window as any).pdfWorkerSrc = `${environment.rootUrl}/assets/scripts/pdf.worker.min.js`;
  }

  private configureModal(): void {
    const id = 'ms-file-modal';
    const body =
      'Downloading Microsoft Office documents is not supported on this device. Open on Manager’s desktop computer.';
    const title = 'Error';
    this.modalConfig = {
      title,
      body,
      id
    };
  }

  // open modal
  public openModal(id: string) {
    this.modalService.open(id);
  }

  // close modal
  public closeModal(id: string) {
    this.modalService.close(id);
  }
}
