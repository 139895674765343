import { environment } from '@env';
import { DocumentFolderContent } from './../../data/models/documentFolderContents';
import { Injectable } from '@angular/core';
import { FileService } from './file.service';
import { DocumentViewerService } from './document-viewer.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import {
  DocumentViewerModal,
  FileType,
  TakeActionItemViewModel,
  NeedToKnowItemViewModel,
  DocumentFileType
} from '@models';
import { UrlUtility } from '@app/utilities/url-utility';
import { Observable, ReplaySubject } from 'rxjs';
import { MimeTypeUtility } from '@app/utilities/mime-utility';
import { OverlayService } from './overlay.service';

@Injectable({
  providedIn: 'root'
})
export class AppLinkService {
  constructor(
    private fileService: FileService,
    private documentViewerService: DocumentViewerService,
    private router: Router,
    private overlayService: OverlayService
  ) {}

  private linkToDailyTaskItem: ReplaySubject<
    TakeActionItemViewModel
  > = new ReplaySubject<TakeActionItemViewModel>(1);
  private linkToNeedToKnowItem: ReplaySubject<
    NeedToKnowItemViewModel
  > = new ReplaySubject<NeedToKnowItemViewModel>(1);

  public handleLink(url: string, item?: any) {
    if (this.isLinkFileOrAsset(url)) {
      this.handleFileOrAssetLink(url);
    } else {
      if (!url.startsWith('http') || url.startsWith(environment.rootUrl)) {
        this.router.navigateByUrl(url);
      } else {
        window.location.href = url;
      }

      this.overlayService.toggleShowAlerts(false);
      this.overlayService.toggleShowSearch(false);

      if (item) {
        this.passItemToRoute(url, item);
      }
    }
  }

  public onLinkToDailyTaskItem(): Observable<TakeActionItemViewModel> {
    return this.linkToDailyTaskItem.asObservable();
  }

  public onLinkToNeedToKnowItem(): Observable<NeedToKnowItemViewModel> {
    return this.linkToNeedToKnowItem.asObservable();
  }

  public isLinkFileOrAsset(url: string): boolean {
    return url.startsWith('appfile') || url.startsWith('appasset');
  }

  public handleFileOrAssetLink(link: string) {
    const url = new URL(link);
    let protocol = url.protocol;

    if (!protocol) {
      protocol = url.toJSON().match('^.*(?=(//))')[0]; // fix for Safari dropping the protocol
    }

    // prettier-ignore
    const id = +url.toJSON().match(/([\w]+)/g)[1];

    const protocolType = protocol.replace(':', '');

    const title = UrlUtility.getQueryStringParameterByName('title', link);
    const fileName = UrlUtility.getQueryStringParameterByName('name', link);
    const mimetype = UrlUtility.getQueryStringParameterByName('mime', link);
    const searchTerm = UrlUtility.getQueryStringParameterByName(
      'searchTerm',
      link
    );

    const fileType = this.getFileType(protocolType);

    // If we have the title and file name, we don't need to make an API call to fetch them
    if (title && fileName) {
      const docType = MimeTypeUtility.getDocumentFileTypeByMimeType(mimetype);
      this.openDocumentViewer(fileName, title, fileType, docType, searchTerm);
    } else {
      // If we don't have the title and file name, we need to make an API call to fetch them
      this.fileService
        .getFileByType(fileType, id)
        .pipe(take(1))
        .subscribe(file => {
          const docFile = new DocumentFolderContent(file);
          this.openDocumentViewer(
            docFile.name,
            docFile.title,
            fileType,
            docFile.documentType,
            searchTerm
          );
        });
    }
  }

  private openDocumentViewer(
    fileName: string,
    title: string,
    fileType: FileType,
    documentType: DocumentFileType,
    searchTerm?: string
  ) {
    const config: DocumentViewerModal = {
      fileName,
      title,
      documentType,
      fileType,
      searchTerm
    };

    this.documentViewerService.open(config);
  }

  private getFileType(type: string): FileType | null {
    switch (type) {
      case 'appfile':
        return FileType.file;
      case 'appasset':
        return FileType.asset;
      default:
        return null;
    }
  }

  private passItemToRoute(url: string, item?: any) {
    if (!item) {
      return;
    }

    const route = url.toLowerCase();

    if (route.startsWith('/daily-tasks')) {
      const dailyTaskItemVM = new TakeActionItemViewModel(item, null, []);
      this.linkToDailyTaskItem.next(dailyTaskItemVM);
    } else if (route.startsWith('/need-to-know')) {
      const ntkItemVM = !(item instanceof NeedToKnowItemViewModel)
        ? new NeedToKnowItemViewModel(item, null, [])
        : item;
      this.linkToNeedToKnowItem.next(ntkItemVM);
    }
  }
}
