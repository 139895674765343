import { SearchResultViewModel } from 'app/data/models/search-result-view-model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-daily-task-result',
  templateUrl: './search-daily-task-result.component.html',
  styleUrls: ['./search-daily-task-result.component.scss']
})
export class SearchDailyTaskResultComponent {
  @Input()
  public result: SearchResultViewModel;

  constructor() {}
}
