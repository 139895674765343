import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { File, FileType } from '@models';
import { environment } from '@env';
import { LocalStorage } from '@app/utilities/local-storage';
import { APP_CONFIGURATION } from '@app/config/app-configuration';
import { Observable } from 'rxjs';

const BASE_API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private storeCache: string;

  constructor(private httpService: HttpService) {
    this.storeCache = LocalStorage.get(
      APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORE_CACHE
    );
  }

  public getFileByType(fileType: FileType, fileId: number): Observable<File> {
    return this.httpService.get<File>(`/api/v1/files/${fileType}/${fileId}`);
  }

  public async returnFileUrlAsync(
    fileType: FileType,
    filename: string,
    useSignedUri?: boolean,
    bypassStoreCache?: boolean
  ): Promise<URL> {
    if (this.storeCache && !bypassStoreCache) {
      const dfsURL = await this.tryReturnDfsFileUrlAsync(fileType, filename);
      if (dfsURL) { return dfsURL; }
    }

    if (environment.env !== 'local' && useSignedUri) {
      return new Promise<URL>((res, rej) => {
        this.httpService.get<any>(`/api/v1/files/data/${fileType}/${filename}?meta=true`).subscribe(metaData => {
          res(new URL(`${metaData.download_url}?ngsw-bypass=true`));
        },
        rej);
      });
    }

    return this.downloadFile(fileType, filename);
  }

  public returnAPIUrl(url: string): string {
    if (url && url.startsWith(BASE_API_URL)) {
      return url;
    }

    return this.convertUrl(url).href;
  }

  public downloadFile(fileType: FileType, filename: string): URL {
    if (environment.env !== 'local') {
      return new URL(
        `${BASE_API_URL}/api/v1/files/data/${fileType}/${filename}`
        );
    }
    return new URL(
      `${BASE_API_URL}/api/v1/files/data/${fileType}/${filename}?noredirect=true`
      );
  }

  private async tryReturnDfsFileUrlAsync(
    fileType: FileType,
    filename: string
  ): Promise<URL> {
    const dfsUrl = new URL(this.generateDfsFileUrl(fileType, filename));

    const dfsFileExists = await this.httpService.fetchFile(dfsUrl.href);

    if (dfsFileExists) {
      return dfsUrl;
    }
  }

  public generateDfsFileUrl(fileType: FileType, filename: string): string {
    return fileType.toLowerCase() === FileType.asset
      ? `https://${this.storeCache}/TheSource/assets/${filename}`
      : `https://${this.storeCache}/TheSource/storage/${filename}`;
  }

  public getStoreCache() {
    return this.storeCache;
  }

  private convertUrl(url: string): URL {
    const filename = url.substring(url.lastIndexOf('/') + 1);

    if (url.indexOf(`/storage/`) !== -1) {
      return this.downloadFile(FileType.file, filename);
    }

    return this.downloadFile(FileType.asset, filename);
  }
}
