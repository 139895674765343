import { Component, OnInit, Input } from '@angular/core';
import { LayoutAbsoluteCenterViewModel } from '@models';

@Component({
  selector: 'app-layout-absolute-center',
  templateUrl: './layout-absolute-center.component.html',
  styleUrls: ['./layout-absolute-center.component.scss']
})
export class LayoutAbsoluteCenterComponent implements OnInit {
  @Input() layoutConfig: LayoutAbsoluteCenterViewModel;
  public config: LayoutAbsoluteCenterViewModel;

  constructor() {}

  ngOnInit() {
    this.config = this.layoutConfig;
  }
}
