import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { Modal } from '@models';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() config: Modal;
  private element: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = el.nativeElement;
  }

  @HostListener('click', ['$event'])
  public documentClick(e: any): void {
    if (e.target.className === 'modal__backdrop') {
      this.close();
    }
  }

  ngOnInit() {
    this.renderer.appendChild(document.body, this.element);
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    if (this.config) {
      this.modalService.remove(this.config.id);
    }
    this.element.remove();
  }

  // open modal
  open(): void {
    this.renderer.setStyle(this.element, 'display', 'block');
  }

  // close modal
  close(): void {
    this.renderer.setStyle(this.element, 'display', 'none');
  }
}
