<div class="modal" *ngIf="config">
  <div class="modal__backdrop"></div>
  <div class="modal__overlay">
    <div class="modal__overlay__pane">
      <div class="modal__dialog">
        <div class="modal__dialog__title">
          {{config.title}}
        </div>
        <div class="modal__dialog__body" [innerHTML]="config.body"></div>
        <div class="modal__dialog__actions">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
