import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 3rd party modules
import { MaterialModule } from './../modules/material/material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import {
  AlertComponent,
  BannerComponent,
  DocumentViewerComponent,
  ModalActionComponent,
  ModalComponent,
  SvgIconComponent,
  LayoutAbsoluteCenterComponent,
  StoreListModalComponent,
  StoreListViewComponent,
  SearchBoxComponent,
  SearchResultsComponent,
  SearchDailyTaskResultComponent,
  SearchNeedToKnowResultComponent,
  SearchCalendarEventResultComponent,
  SearchReferenceResultComponent,
  SearchLinkResultComponent
} from '@shared/components';

// directives
import { LinkDirective, ContentLinksDirective } from '@shared/directives';

// pipes
import {
  DeDupePipe,
  OrderByPipe,
  SafeUrlPipe,
  SafeHtmlPipe
} from '@shared/pipes';
import { BannerScrollDirective } from './directives/banner-scroll/banner-scroll.directive';

@NgModule({
  declarations: [
    LayoutAbsoluteCenterComponent,
    SvgIconComponent,
    ModalComponent,
    ModalActionComponent,
    AlertComponent,
    BannerComponent,
    LinkDirective,
    ContentLinksDirective,
    OrderByPipe,
    DeDupePipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    DocumentViewerComponent,
    StoreListModalComponent,
    StoreListViewComponent,
    SearchBoxComponent,
    SearchResultsComponent,
    SearchDailyTaskResultComponent,
    SearchNeedToKnowResultComponent,
    SearchCalendarEventResultComponent,
    SearchReferenceResultComponent,
    BannerScrollDirective,
    SearchLinkResultComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    PdfViewerModule
  ],
  exports: [
    AlertComponent,
    BannerComponent,
    BannerScrollDirective,
    DocumentViewerComponent,
    LayoutAbsoluteCenterComponent,
    SvgIconComponent,
    ModalComponent,
    ModalActionComponent,
    LinkDirective,
    ContentLinksDirective,
    OrderByPipe,
    DeDupePipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SearchBoxComponent,
    SearchResultsComponent,
    StoreListModalComponent,
    StoreListViewComponent
  ]
})
export class SharedModule {}
