import {
  DocumentFolderContent,
  NeedToKnowItemViewModel,
  SvgIcon,
  TakeActionItemViewModel
} from '@models';
import { CalendarEventViewModel } from './calendar-event-view-model';
import { StoreDepartmentViewModel } from './storeDepartmentViewModel';
import { LinkViewModel } from './link-view-model';

export class SearchResultViewModel {
  title: string;
  department?: string;
  icon?: SvgIcon;
  link?: LinkViewModel;
  borderColor?: string;

  constructor(
    searchResult:
      | DocumentFolderContent
      | TakeActionItemViewModel
      | CalendarEventViewModel
      | NeedToKnowItemViewModel,
    link?: LinkViewModel
  ) {
    if (searchResult instanceof DocumentFolderContent) {
      this.title = searchResult.title;
      this.icon = searchResult.icon;
    } else if (searchResult instanceof TakeActionItemViewModel) {
      this.title = searchResult.title;
      this.department = searchResult.department.title;
      this.borderColor = searchResult.department.color;
    } else if (searchResult instanceof CalendarEventViewModel) {
      this.title = searchResult.title;
      this.borderColor = searchResult.borderColor;
    } else if (searchResult instanceof NeedToKnowItemViewModel) {
      this.title = searchResult.title;
    }

    this.link = link;
  }
}
