import { ProxyStoreViewModel } from './../../data/models/proxy-store';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap, share } from 'rxjs/operators';

import { Store } from '@models';
import { HttpService } from './http.service';
import { SessionStorage, APP_CONFIGURATION, LocalStorage } from '@app/index';
import { MockStoreService } from '../../mocks/mock-store-service';
import { StoreViewModel } from 'app/data/models/store-view-model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private storeSubject: BehaviorSubject<ProxyStoreViewModel[]>;

  constructor(private httpService: HttpService) {
    this.storeSubject = new BehaviorSubject<ProxyStoreViewModel[]>(
      JSON.parse(
        LocalStorage.get(APP_CONFIGURATION.LOCAL_STORAGE_KEYS.PROXY_STORE)
      )
    );
  }

  public get storeHierarchyValue(): ProxyStoreViewModel[] {
    return this.storeSubject.value;
  }

  public get storeHierarchy(): Observable<ProxyStoreViewModel[]> {
    return this.storeSubject.asObservable();
  }

  public getStoreHierarchy(): Observable<ProxyStoreViewModel[]> {
    return this.httpService.get<Store[]>('/api/v1/app/store-hierarchy').pipe(
      map(stores => {
        const proxyStoreVM: ProxyStoreViewModel[] = [];
        if (stores && stores.length > 0) {
          stores.forEach(store => {
            proxyStoreVM.push(new ProxyStoreViewModel(store));
          });
        }
        LocalStorage.set(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.PROXY_STORE,
          JSON.stringify(proxyStoreVM)
        );

        return proxyStoreVM;
      }),
      tap((stores: ProxyStoreViewModel[]) => this.storeSubject.next(stores))
    );
  }

  public getUserStores(): Observable<StoreViewModel[]> {
    const cachedStores = SessionStorage.get(
      APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER_STORES
    );
    if (cachedStores) {
      return of<StoreViewModel[]>(JSON.parse(cachedStores));
    }

    return this.httpService.get<Store[]>('/api/v1/app/user/stores').pipe(
      map((stores: Store[]) => stores.map(store => new StoreViewModel(store))),
      tap((stores: StoreViewModel[]) => {
        SessionStorage.set(
          APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER_STORES,
          JSON.stringify(stores)
        );
      })
    );
  }
}
