<div
  class="alert"
  *ngIf="alert"
  [ngClass]="{
    'alert--highPriority': alert.highPriority,
    'alert--lowPriority': !alert.highPriority,
    'alert--link': !!alert.link,
    'alert__need-to-know__border': alert.isLowPriorityActiveNeedToKnow
  }"
  [ngStyle]="getDailyTaskBorderColor()"
  (click)="navigate()"
>
  <div class="alert__content">
    <div *ngIf="!isDashboardAlert" class="alert__content__timestamp">
      {{ alert.timestamp | date: 'EEEE, M/dd/yy • h:mm a '
      }}{{ alert.timezone }}
    </div>
    <div
      class="alert__content__icon"
      [ngClass]="{
        'alert__need-to-know__icon': alert.isLowPriorityActiveNeedToKnow
      }"
      [ngStyle]="getDailyTaskIconColor()"
    >
      <app-svg-icon [config]="alert.icon"></app-svg-icon>
    </div>
    <div class="alert__content__titleIndicatorContainer">
      <div class="alert__content__title">
        {{ alert.title }}
      </div>
      <div class="alert__content__linkIndicator">
        <app-svg-icon
          iconName="chevron-small-right-currentColor"
          *ngIf="alert.link && !isDashboardAlert"
        ></app-svg-icon>
        <app-svg-icon
          iconName="close-light"
          *ngIf="isDashboardAlert"
          (click)="handleDashboardClose(); $event.stopPropagation()"
          class="alert__content__linkIndicator--link"
        ></app-svg-icon>
      </div>
    </div>
  </div>
</div>
