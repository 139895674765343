import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';
import { Event, Router, NavigationEnd } from '@angular/router';
import { filter, tap, take } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private showSearchSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  private showAlertsSubject: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private alertService: AlertService
  ) {}

  public hideOverlaysOnRoute(): Observable<Event> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => {
        this.toggleShowSearch(false);
        this.toggleShowAlerts(false);
      })
    );
  }

  // SEARCH

  public getShowSearch(): Observable<boolean> {
    return this.showSearchSubject.asObservable();
  }

  public toggleShowSearch(showOverride?: boolean) {
    const isShown =
      showOverride !== undefined ? showOverride : !this.showSearchSubject.value;

    if (this.showSearchSubject.value !== isShown) {
      if (isShown) {
        this.toggleShowAlerts(false);
        this.breadcrumbService.updateTitle('Search');
      } else {
        this.breadcrumbService.revertTitle();
      }
      this.showSearchSubject.next(isShown);
    }
  }

  // ALERTS

  public getShowAlerts(): Observable<boolean> {
    return this.showAlertsSubject.asObservable();
  }

  public getShowAlertsValue(): boolean {
    return this.showAlertsSubject.value;
  }

  public toggleShowAlerts(showOverride?: boolean) {
    const isShown =
      showOverride !== undefined ? showOverride : !this.showAlertsSubject.value;

    if (this.showAlertsSubject.value !== isShown) {
      if (isShown) {
        this.alertService
          .alerts(true)
          .pipe(take(1))
          .subscribe();
        this.toggleShowSearch(false);
        this.breadcrumbService.updateTitle('Alerts');
      } else {
        this.breadcrumbService.revertTitle();
      }
      this.showAlertsSubject.next(isShown);
    }
  }
}
