import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appBannerScroll]'
})
export class BannerScrollDirective {
  private lastScrollY = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('scroll') onScroll() {
    const unit = 'px';
    const titleStartingPosition = 100;
    const titleEndingPosition = 200;

    const scrollElement = this.el.nativeElement as HTMLDivElement;
    const titleElement = scrollElement.getElementsByTagName('h1')[0];
    const scrollY = scrollElement.scrollTop;

    if (scrollY === 0) {
      this.renderer.removeClass(scrollElement, 'banner--sticky');
      if (titleElement) {
        this.renderer.setStyle(titleElement, 'top', `${titleStartingPosition}${unit}`);
      }
      return;
    }

    this.renderer.addClass(scrollElement, 'banner--sticky');

    if (titleElement) {
      if (scrollY > titleEndingPosition) {
        this.renderer.setStyle(titleElement, 'top', `${titleEndingPosition}${unit}`);
        return;
      } else if (scrollY < titleStartingPosition) {
        this.renderer.setStyle(titleElement, 'top', `${titleStartingPosition}${unit}`);
        return;
      } else if (
        scrollY >= titleStartingPosition &&
        scrollY <= titleEndingPosition
      ) {
        this.renderer.setStyle(titleElement, 'top', `${scrollY}${unit}`);
      }
    }

    this.lastScrollY = scrollY;
  }
}
