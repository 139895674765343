import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil
} from 'rxjs/operators';
import { SearchService } from '@app/services/search.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  @ViewChild('searchInput') searchBox: ElementRef;

  constructor(private searchService: SearchService) {}

  ngAfterViewInit() {
    this.searchBox.nativeElement.focus();
    fromEvent(this.searchBox.nativeElement, 'keyup')
      .pipe(
        filter(() => !this.isQueryNullOrWhiteSpace()),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() =>
          this.searchService.setSearchQuery(this.searchBox.nativeElement.value)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    this.searchService.setSearchQuery('');
  }

  private isQueryNullOrWhiteSpace() {
    const query = this.searchBox.nativeElement.value;
    if (typeof query === 'undefined' || query == null) {
      return true;
    }

    return query.replace(/\s/g, '').length < 3;
  }
}
