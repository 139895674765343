<div
  class="store-list-view"
  *ngIf="shouldShowStoreListButton"
  (click)="openStoreListModel()"
  [ngClass]="{ 'store-list-view--black': textBlack }"
>
  View Store List
</div>

<!-- View store list Modal -->
<app-store-list-modal [config]="storeListModalConfig"></app-store-list-modal>
