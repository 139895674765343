import { ColorUtility } from '@app/utilities';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from '@angular/core';

import { environment } from '@env';
import { FileService } from '@app/services';
import { Asset, FileType } from '@models';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnChanges {
  @Input() bannerImage?: Asset;
  @Input() bannerTitle?: string;
  public bannerUrl: string;
  public defaultImageMap: string;
  public showTitle: boolean;

  constructor(private fileService: FileService) {}

  ngOnInit() {
    this.configureBannerImage(this.bannerImage);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(this.scrollToTop, 300);

    const imageChange = changes.bannerImage;
    if (!imageChange) {
      return;
    }
    if (imageChange.currentValue === imageChange.previousValue) {
      return;
    }

    this.configureBannerImage(imageChange.currentValue);
  }

  private configureBannerImage(bannerImage: Asset) {
    this.showTitle = false;

    if (bannerImage) {
      this.fileService
        .returnFileUrlAsync(FileType.asset, bannerImage.filename)
        .then(url => {
          this.defaultImageMap = null;
          this.bannerUrl = url.toJSON();
        });
    } else {
      this.configureDefaultImageMap();
    }
  }

  // creates a responsive array of src's so the correct image is loaded.
  private configureDefaultImageMap() {
    this.bannerUrl = '';
    this.defaultImageMap = [
      `${environment.rootUrl}/assets/images/banner-default-black.png 1024w`,
      `${environment.rootUrl}/assets/images/banner-default-black@2x.png 2048w`,
      `${environment.rootUrl}/assets/images/banner-default-black@3x.png 3072w`
    ].toString();

    this.showTitle = true;
  }

  // show default image if image fails to load
  public imageFailedHandler() {
    this.configureDefaultImageMap();
  }

  public onImgLoaded() {
    const img = document.getElementById('banner-img') as HTMLImageElement;

    const bkgd = ColorUtility.getRGBAColorFromImageXY(img, 100, 100);
    let rgba = [0, 0, 0, 1];

    if (
      ColorUtility.whiteOverBackground(bkgd) >=
      ColorUtility.blackOverBackground(bkgd)
    ) {
      rgba = [255, 255, 255, 1];
    }

    this.setBannerTextColors(rgba);
  }

  private setBannerTextColors(rgba: number[]) {
    const color = ColorUtility.rgba(rgba);
    const inverseColor = ColorUtility.rgba(ColorUtility.inverseColor(rgba));
    const doc = document.documentElement;
    doc.style.setProperty('--app-bannerTxt--color', color);
    doc.style.setProperty('--app-bannerTxt--shadow', inverseColor);
  }

  private scrollToTop() {
    const dateEl = document.getElementById('scrollToTop');

    if (dateEl) {
      dateEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }
}
