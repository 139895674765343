import { ReplaySubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private stateSubject = new ReplaySubject<void>(1);

  constructor() {}

  public stateChanged(): ReplaySubject<void> {
    return this.stateSubject;
  }

  public resetState() {
    this.stateSubject.next();
  }
}
