import { SvgIcon } from '@models';
import { Component, OnInit, Input } from '@angular/core';
import { MatBadge } from '@angular/material/badge';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  @Input() config: SvgIcon;
  @Input() iconName: string;
  @Input() includeDropShadow = false;
  @Input() matBadge: MatBadge;
  @Input() matBadgeHidden: MatBadge;

  constructor() {}

  // todo: go back and refactor to only use icon name
  ngOnInit() {
    if (this.iconName) {
      this.config = new SvgIcon(this.iconName);
    }
  }
}
