import { Component, Input, OnInit } from '@angular/core';
import { SearchResultViewModel } from 'app/data/models/search-result-view-model';

@Component({
  selector: 'app-search-link-result',
  templateUrl: './search-link-result.component.html',
  styleUrls: ['./search-link-result.component.scss']
})
export class SearchLinkResultComponent {
  @Input()
  public result: SearchResultViewModel;

  constructor() { }


}
