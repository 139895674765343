import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, defaultIfEmpty } from 'rxjs/operators';
import { HttpService } from './http.service';
import {
  NeedToKnow,
  NeedToKnowItemViewModel,
  TakeAction,
  CalendarEvent,
  DocumentFolderContent,
  File,
  TakeActionItemViewModel,
  CalendarEventViewModel,
  SearchableContent,
  LinkViewModel,
  RemoteFile,
} from '@models';
import { SearchResultViewModel } from 'app/data/models/search-result-view-model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchQuerySubject: BehaviorSubject<string> = new BehaviorSubject<
    string
  >('');

  constructor(private httpService: HttpService) {}

  public searchQuery(): Observable<string> {
    return this.searchQuerySubject.asObservable();
  }

  public setSearchQuery(searchText: string) {
    this.searchQuerySubject.next(searchText);
  }

  public performSearch(
    query: string,
    type: SearchableContent
  ): Observable<SearchResultViewModel[]> {
    switch (type) {
      case SearchableContent.documents:
        return this.performReferenceSearch(query);
      case SearchableContent.links:
        return this.performLinkSearch(query);
      case SearchableContent.dailyTasks:
        return this.performDailyTasksSearch(query);
      case SearchableContent.calendar:
        return this.performCalendarSearch(query);
      case SearchableContent.needToKnow:
        return this.performNeedToKnowSearch(query);
      default:
        break;
    }
  }

  public performReferenceSearch(
    searchText: string
  ): Observable<SearchResultViewModel[]> {
    return this.httpService
      .post<File[]>(
        '/api/v1/search/storage_file',
        {
          query: searchText
        },
        false
      )
      .pipe(
        map((results: File []) => {
          let viewModels: SearchResultViewModel[] = [];
          if (results && results.length > 0) {
            viewModels = results.map(result => {
              const link = new LinkViewModel({ file: result }, searchText);
              return new SearchResultViewModel(
                new DocumentFolderContent(result),
                link
              );
            });
          }
          return viewModels;
        }),
        defaultIfEmpty([])
      );
  }

  public performLinkSearch(
    searchText: string
  ): Observable<SearchResultViewModel[]> {
    return this.httpService
      .post<RemoteFile[]>(
        '/api/v1/search/remote_file',
        {
          query: searchText
        },
        false
      )
      .pipe(
        map((results: RemoteFile []) => {
          let viewModels: SearchResultViewModel[] = [];
          if (results && results.length > 0) {
            viewModels = results.map(result => {
              const link = new LinkViewModel({ remote_file: result }, searchText);
              return new SearchResultViewModel(
                new DocumentFolderContent(result),
                link
              );
            });
          }
          return viewModels;
        }),
        defaultIfEmpty([])
      );
  }

  public performDailyTasksSearch(
    searchText: string
  ): Observable<SearchResultViewModel[]> {
    return this.httpService
      .post<TakeAction[]>(
        '/api/v1/search/take_action',
        { query: searchText },
        false
      )
      .pipe(
        map((results: TakeAction[]) => {
          let viewModels: SearchResultViewModel[] = [];
          if (results && results.length > 0) {
            viewModels = results.map(result => {
              const link = new LinkViewModel({ take_action: result });
              return new SearchResultViewModel(
                new TakeActionItemViewModel(result, null, []),
                link
              );
            });
          }
          return viewModels;
        }),
        defaultIfEmpty([])
      );
  }

  public performCalendarSearch(
    searchText: string
  ): Observable<SearchResultViewModel[]> {
    return this.httpService
      .post<CalendarEvent[]>(
        '/api/v1/search/calendar_event',
        {
          query: searchText
        },
        false
      )
      .pipe(
        map((results: CalendarEvent[]) => {
          let viewModels: SearchResultViewModel[] = [];
          if (results && results.length > 0) {
            viewModels = results.map(result => {
              const link = new LinkViewModel({ calendar_event: result });
              return new SearchResultViewModel(
                CalendarEventViewModel.buildFromCalendarEvent(result),
                link
              );
            });
          }
          return viewModels;
        }),
        defaultIfEmpty([])
      );
  }

  public performNeedToKnowSearch(
    searchText: string
  ): Observable<SearchResultViewModel[]> {
    return this.httpService
      .post<NeedToKnow[]>(
        '/api/v1/search/need_to_know',
        { query: searchText },
        false
      )
      .pipe(
        map((results: NeedToKnow[]) => {
          let viewModels: SearchResultViewModel[] = [];
          if (results && results.length > 0) {
            viewModels = results.map(result => {
              const link = new LinkViewModel({ need_to_know: result });
              return new SearchResultViewModel(
                new NeedToKnowItemViewModel(result, null, []),
                link
              );
            });
          }
          return viewModels;
        }),
        defaultIfEmpty([])
      );
  }
}
