import {
  Directive,
  ElementRef,
  Input,
  AfterViewInit,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { LinkViewModel, Target } from '@models';
import { AppLinkService } from '@app/services';

@Directive({
  selector: '[appLink]'
})
export class LinkDirective implements AfterViewInit, OnDestroy {
  @Input() link: LinkViewModel;

  private linkClick: () => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private linkService: AppLinkService
  ) {}

  ngAfterViewInit(): void {
    if (this.el && this.el.nativeElement) {
      if (this.link && !this.link.url) {
        return;
      }

      let anchor: HTMLAnchorElement;
      let elementIsAnchor = false;

      if (this.el.nativeElement instanceof HTMLAnchorElement) {
        elementIsAnchor = true;
        anchor = this.el.nativeElement;
      } else {
        anchor = document.createElement('a');
        anchor.style.width = '100%';
        anchor.style.textDecoration = 'none';
      }

      if (this.link.target === Target.blank) {
        anchor.target = this.link.target;

        if (this.link.url instanceof URL) {
          anchor.href = this.link.url.href;
        } else {
          anchor.href = this.link.url;
        }
      } else {
        anchor.target = Target.self;
        this.linkClick = this.renderer.listen(anchor, 'click', () => {
          this.handleLinkEvent();
        });
      }

      if (!elementIsAnchor) {
        const parent: ElementRef = this.renderer.parentNode(
          this.el.nativeElement
        );
        this.renderer.appendChild(parent, anchor);
        this.renderer.appendChild(anchor, this.el.nativeElement);
      }
    }
  }

  ngOnDestroy() {
    if (this.linkClick) {
      this.linkClick();
    }
  }

  private handleLinkEvent(): any {
    this.linkService.handleLink(this.link.url.toString(), this.link.item);
  }
}
