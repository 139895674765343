import { Component, OnInit, Input } from '@angular/core';

import { SvgIcon, AlertViewModel } from '@models';
import { AppLinkService, AlertService } from '@app/services';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() alert: AlertViewModel;
  @Input() isDashboardAlert: boolean;

  public iconClose = new SvgIcon('close-light');

  constructor(
    private linkService: AppLinkService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  public navigate() {
    if (this.alert.link) {
      if (!this.alert.link.target) {
        this.linkService.handleLink(
          this.alert.link.url.toString(),
          this.alert.link.item
        );
      } else {
        window.open(this.alert.link.url as string, this.alert.link.target);
      }
    }
  }

  public handleDashboardClose() {
    this.alertService.hideDashboardAlert(this.alert.id);
  }

  public getDailyTaskBorderColor() {
    return this.alert.color
      ? {
          'border-left-color': this.alert.color
        }
      : {};
  }

  public getDailyTaskIconColor() {
    return this.alert.color
      ? {
          color: this.alert.color
        }
      : {};
  }
}
