import { Injectable } from '@angular/core';
import { DocumentViewerModal } from '@models';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { OverlayService } from './overlay.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentViewerService {
  private documentViewerOpenSubject: BehaviorSubject<DocumentViewerModal> = new BehaviorSubject<
    DocumentViewerModal
  >(null);

  private documentViewerCloseSubject: Subject<void> = new Subject<void>();

  constructor(private overlayService: OverlayService) {}

  public open(config: DocumentViewerModal): void {
    this.documentViewerOpenSubject.next(config);
    this.overlayService.toggleShowAlerts(false);
  }

  public onOpen(): Observable<DocumentViewerModal> {
    return this.documentViewerOpenSubject.asObservable();
  }

  public close(): void {
    this.documentViewerCloseSubject.next();
  }

  public onClose(): Observable<void> {
    return this.documentViewerCloseSubject.asObservable();
  }
}
