import {
  Component,
  OnInit,
  Input,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { StoreListModal } from '@models';
import { ModalService, StoreService } from '@app/services';

import { StoreViewModel, StoreGroupViewModel } from '@models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-store-list-modal',
  templateUrl: './store-list-modal.component.html',
  styleUrls: ['./store-list-modal.component.scss']
})
export class StoreListModalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  @Input() config: StoreListModal;

  public storeGroups: StoreGroupViewModel[] = [];

  private element: any;

  private userStores: StoreViewModel[] = [];

  constructor(
    private modalService: ModalService,
    private storeService: StoreService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = el.nativeElement;
  }

  @HostListener('click', ['$event'])
  public documentClick(e: any): void {
    if (e.target.className === 'store-list-modal__backdrop') {
      this.close();
    }
  }

  ngOnInit() {
    this.storeService
      .getUserStores()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(stores => {
        this.userStores = stores;
      });

    this.renderer.appendChild(document.body, this.element);
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    if (this.config) {
      this.modalService.remove(this.config.id);
    }
    this.element.remove();
  }

  // open modal
  open(): void {
    this.buildDisplayStoreGroups();
    this.renderer.setStyle(this.element, 'display', 'block');
  }

  // close modal
  close(): void {
    this.storeGroups = [];
    this.renderer.setStyle(this.element, 'display', 'none');
  }

  private buildDisplayStoreGroups() {
    this.config.storeGroups.forEach((group: StoreGroupViewModel) => {
      group.stores.forEach((store: string) => {
        const userStore = this.userStores.find(x => x.storeNumber === store);

        if (userStore) {
          if (userStore.storeName) {
            store = `${store} - ${userStore.storeName}`;
          }

          let userGroup = this.storeGroups.find(x => x.title === group.title);
          if (!userGroup) {
            userGroup = {
              title: group.title,
              id: group.id,
              stores: []
            };

            this.storeGroups.push(userGroup);
          }

          userGroup.stores.push(store);
        }
      });
    });
  }
}
