import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deDupe'
})
export class DeDupePipe implements PipeTransform {
  transform(value: any[] | null, key: string): any {
    if (value && value.length) {
      return value.reduce((prev, crnt) => {
        if (crnt[key] && !prev.some((item: any) => item[key] === crnt[key])) {
          return [...prev, crnt];
        } else {
          return prev;
        }
      }, []);
    } else {
      return null;
    }
  }
}
