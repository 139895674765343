<div class="store-list-modal" *ngIf="config">
  <div class="store-list-modal__backdrop"></div>
  <div class="store-list-modal__overlay">
    <div class="store-list-modal__overlay__pane">
      <div class="store-list-modal__dialog">
        <div class="store-list-modal__dialog__title">
          STORE LIST
          <app-svg-icon
            class="store-list-modal__dialog__title--close"
            iconName="close-light"
            (click)="close()"
          ></app-svg-icon>
        </div>
        <div class="store-list-modal__dialog__body">
          <div *ngFor="let group of storeGroups">
            <div class="store-list-modal__dialog__body__group">
              {{ group.title }}
            </div>
            <div class="store-list-modal__dialog__body__stores">
              <ul>
                <li *ngFor="let store of group.stores">
                  {{ store }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
